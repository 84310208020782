<template>
  <div>
    <div id="nav-icon" v-on:click="clickMenu" >
      <span/><span/><span/><span/><span/>
      <span>
        <img id="logo" src="../../assets/geek9_logo.jpg" style="width:60px;height:15px;" alt="geek9.kr">
      </span>
    </div>
    <nav id="floating-menu" class="card main">
      <div v-if="this.api.getCookies('login') === 'true' || this.api.getCookies('login') === true">
        <div class="menu">
          <img id="user_pic" class="user_pic link" @click="this.router('myinfo')" src="" alt="geek9.kr">
          <span id="user_name" class="link" @click="this.router('myinfo')" style="vertical-align: middle;"/>&nbsp;
        </div>
        <a href="/">Home</a>
        <a @click="showModal('address')" class="link">배송주소</a>
        <a href="/order">대행신청</a>
        <a @click="$parent.phref('estimate_section')" class="link">예상견적</a>
        <a href="/list">주문조회</a>
        <a href="/shop">해외중고샵</a>
        <a href="/qna">문의</a>
        <a href="/faq">필독</a>
        <a href="/mypage">MY</a>
        <a href="/contract">기업고객</a>
        <a @click="this.$emit('logout')" class="link">로그아웃</a>
        <a v-if="this.api.getCookies('id') === kakaoId || this.api.getCookies('id') === naverId" href="/admin/" class="link">관리자</a>
      </div>
      <div v-else>
        <div class="menu">
          <span id="login" @click="this.$emit('login')">로그인해주세요</span>
        </div>
        <a href="/">Home</a>
        <a @click="showModal('address')" class="link">배송주소</a>
        <a @click="$parent.phref('estimate_section')" class="link">예상견적</a>
        <a v-if="this.api.getCookies('mail') === 'geek9@kakao.com'" href="/shop">해외중고샵</a>
        <a href="/qna">문의</a>
        <a @click="this.$emit('login')" class="link">로그인</a>
        <a href="/list">주문조회</a>
        <a href="/faq">필독</a>
        <a href="/contract">기업고객</a>
      </div>
    </nav>
  </div>
  <div id="modals"></div>
  <!-- <registModal v-if="isRegist" @closeRegistModal="closeRegistModal" @login="login" :loginId="loginId"/> -->
</template>

<script>
require('dotenv').config()
import router from '../../router'
// import registModal from '@/components/common/RegistModal.vue'
// import { ref } from 'vue'

export default {
  emits: ["showModal", "logout", "login"],
  mounted(){
    if(window.$cookies.get('login') === 'true') {
      document.getElementById("user_name").innerHTML = window.$cookies.get('nickname')
      document.getElementById("user_pic").src = window.$cookies.get('img')
    } 
  },
  components: {
  },
  setup() {
    return {
    }
  },
  data() { 
    return { 
      naverId:process.env.VUE_APP_NAVER_SOOSAEM_ID,
      kakaoId:process.env.VUE_APP_KAKAO_ID,
    }
  },
  methods: {
    emits: ["showModal", "login", "logout"],
    showModal(param) {
      this.$emit("showModal", param)
    },
    clickMenu() {
      const attr = document.getElementById("floating-menu").style.visibility;
      var menu = document.getElementById("nav-icon");
      if(attr === 'visible') {
        menu.setAttribute("class", "");
        document.getElementById("floating-menu").style.visibility = 'hidden'
      } else {
        menu.setAttribute("class", "open");
        document.getElementById("floating-menu").style.visibility = 'visible'
      }
    }, 
    router(page) {
      this.api.setPage(page)
      router.push({ name: page})
    }
  }
}
</script>

<style scoped>
.menu {
  border-bottom: 1px dotted gray;
  padding: 5px;
  min-height: 25px;
}
#floating-menu {
  visibility: hidden;
  background: white;
  padding: 5px;;
  width: 150px;
  position:fixed;
  bottom:105px;
  right:30px;
  z-index: 100;
  margin-bottom: 20px;
  text-align: center;
  /* border: 1px dotted black; */
  /* box-shadow: 3px 3px 3px  lightgray; */
}
#floating-menu a, #floating-menu h3 {
  font-size: 0.9em;
  display: block;
  margin: 10px;
  color: black;
}
#chat-icon {
  position:fixed;
  bottom:30px;
  right:90px;
  z-index: 999;
  cursor: pointer;
}
#nav-icon {
  width: 50px;
  position:fixed;
  bottom:110px;
  right:30px;
  z-index: 999;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}
#nav-icon span {
  display: block;
  position: absolute;
  height: 7px;
  width: 100%;
  background: #ffa07a;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}
#nav-icon span:nth-child(1) {
  top: 0px;
}
#nav-icon span:nth-child(2) {
  top: 7px;
  background-color: transparent;
}
#nav-icon span:nth-child(3) {
  top: 14px;
}
#nav-icon span:nth-child(4) {
  top: 21px;
  background-color: transparent;
}
#nav-icon span:nth-child(5) {
  top: 28px;
}
#nav-icon span:nth-child(6) {
  background-color: white;
  top: 42px;
}
#nav-icon.open span:nth-child(1) {
  top: 14px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}
#nav-icon.open span:nth-child(3) {
  opacity: 0;
  left: -60px;
}
#nav-icon.open span:nth-child(5) {
  top: 14px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
#logo {
  max-width: 55px;
}
</style>